import type { UseSeoMetaInput } from "@unhead/vue";

export const generateSeoMetaData = (args: {
  appUrl: string;
  title: string;
  description: string;
  imageUrl: string;
  twitterDescription?: string;
}): UseSeoMetaInput => {
  const { appUrl, imageUrl, title, description, twitterDescription } = args;
  const shareImage = () => `${imageUrl}/vue-pdf-viewer-sharable-cover.webp`;
  return {
    title,
    description,
    ogUrl: appUrl,
    ogTitle: title,
    ogDescription:
      "The best choice for Vue.js developers looking to display PDF on Vue.js and Nuxt websites.",
    ogImage: shareImage,
    ogImageAlt:
      "An image of Vue PDF Viewer explaining that it is powerful, reliable and flexible",
    ogType: "website",
    ogSiteName: "Vue PDF Viewer",
    twitterTitle: title,
    twitterDescription:
      twitterDescription ??
      "The best choice for Vue.js developers looking to display PDF on Vue.js and Nuxt websites.",
    twitterImage: shareImage,
    twitterCard: "summary_large_image",
    twitterSite: appUrl,
  };
};

const presetJsonLd = {
  "@context": "https://schema.org/",
  "@type": "WebSite",
  "@id": "#WebPage",
  name: "Vue PDF Viewer",
  sameAs: ["https://twitter.com/VuePDF"],
};

export const getJsonLD = (args: { appUrl: string; description: string }) => {
  const { appUrl, description } = args;
  return {
    ...presetJsonLd,
    url: appUrl,
    description,
    image: `${appUrl}/vue-pdf-viewer-logo.svg`,
  };
};
